// Enum for card suits
export const CardSuit = {
	Spade: 'spade',
	Diamond: 'diamond',
	Club: 'club',
	Heart: 'heart',
	Joker: 'joker',
};

// Enum for card values, including special types for jokers
export const CardNumber = {
	Ace: 1,
	Two: 2,
	Three: 3,
	Four: 4,
	Five: 5,
	Six: 6,
	Seven: 7,
	Eight: 8,
	Nine: 9,
	Ten: 10,
	J: 11,
	Q: 12,
	K: 13,
	SmallJoker: 16,
	BigJoker: 17,
};

export class Card {
	constructor(suit, number) {
		this.suit = suit;
		this.number = number;
	}
}
