import { Card } from './card';
import { CardNumber } from '../js/Card';
import React from 'react';
import { determineCardCombo } from '../js/CardCombo';

export function Deck({ cards = [], cardComboOnTable, isMyTurn, mesh }) {
	cards.sort((a, b) => a.number - b.number);
	const [selectedCards, setSelectedCards] = React.useState([]);
	const [isValidCombo, setIsValidCombo] = React.useState(false);

	const toggleCardSelection = (index) => {
		setSelectedCards((prevSelected) => {
			let newSelected;
			if (prevSelected.includes(index)) {
				// Deselect if already selected
				newSelected = prevSelected.filter((i) => i !== index);
			} else {
				// Select if not already selected
				newSelected = [...prevSelected, index];
			}

			// Check if the new selection is a valid combo
			const selectedCards = newSelected.map((i) => cards[i]);
			const combo = determineCardCombo(selectedCards);
			setIsValidCombo(
				(combo !== null && cardComboOnTable == null) ||
					(combo !== null &&
						combo.isValidMoveAfter(cardComboOnTable, CardNumber.Two)),
			);

			return newSelected;
		});
	};
	// Constants for rows and cards per row
	const rows = 5;
	const cardsPerRow = 6;
	const maxCards = 27;

	// Function to render the rows of cards
	const renderRows = () => {
		let content = [];
		for (let row = 0; row < rows; row++) {
			let rowCards = [];
			for (
				let i = 0;
				i < cardsPerRow && row * cardsPerRow + i < maxCards;
				i++
			) {
				const cardIndex = row * cardsPerRow + i;
				if (cardIndex < cards.length) {
					rowCards.push(
						<Card
							key={cardIndex}
							card={cards[cardIndex]}
							isSelected={selectedCards.includes(cardIndex)}
							onClick={() => toggleCardSelection(cardIndex)}
						/>,
					);
				} else {
					rowCards.push(<Card key={cardIndex} card={undefined} />);
				}
			}

			if (row === rows - 1) {
				if (isMyTurn) {
					// Add the "Play Combo" button to the last row
					rowCards.push(
						<button
							key="skipTurnButton"
							onClick={handleSkipTurn}
							className="skip-turn-button"
						>
							Skip
						</button>,
						<button
							key="playComboButton"
							disabled={!isValidCombo}
							onClick={handlePlayCombo}
							className="play-combo-button"
						>
							Play
						</button>,
					);
				} else {
					rowCards.push(
						<button
							key="turnPendingButton"
							className="turn-pending-button"
							disabled
						>
							Waiting ...
						</button>,
					);
				}
			}

			// Add a special class to the last row to center it if it has less than 6 cards
			const rowClass =
				row === rows - 1 && rowCards.length < cardsPerRow
					? 'row last-row'
					: 'row';

			content.push(
				<div key={row} className={rowClass}>
					{rowCards}
				</div>,
			);
		}
		return content;
	};

	const handlePlayCombo = () => {
		const cardCombo = determineCardCombo(selectedCards.map((i) => cards[i]));
		mesh.sharedCache.set('cardComboOnTable', {
			type: cardCombo.type,
			cards: cardCombo.cards,
		});
		const unselectedCards = cards.filter((_, i) => !selectedCards.includes(i));
		mesh.sharedCache.set('cards-' + mesh.id, unselectedCards);
		setSelectedCards([]);
		setIsValidCombo(false);
		const sequence = mesh.sharedCache.get('sequence');
		mesh.sharedCache.set(
			'turn',
			sequence[(sequence.indexOf(mesh.id) + 1) % sequence.length],
		);
		mesh.sharedCache.set('last-played', mesh.id);

		// Further actions can be added here, such as updating the game state
	};

	const handleSkipTurn = () => {
		setSelectedCards([]);
		setIsValidCombo(false);

		const sequence = mesh.sharedCache.get('sequence');
		mesh.sharedCache.set(
			'turn',
			sequence[(sequence.indexOf(mesh.id) + 1) % sequence.length],
		);
		// Additional logic for skipping turn can be added here
	};

	return <div className="deck">{renderRows()}</div>;
}
