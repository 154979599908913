import { CardNumber } from './Card';

export const ComboType = {
	SingleCard: 'SingleCard',
	Pair: 'Pair',
	Triple: 'Triple',
	TripleWithPair: 'TripleWithPair',
	ConsecutivePairs: 'ConsecutivePairs',
	TwoConsecutiveTriples: 'TwoConsecutiveTriples',
	Bomb: 'Bomb',
	Straight: 'Straight',
	StraightFlush: 'StraightFlush',
	SkyBomb: 'SkyBomb',
};

export class CardCombo {
	constructor(type, cards) {
		this.type = type;
		this.cards = cards;
	}

	getValue(level) {
		// Helper function to get the adjusted value of a card based on level and Ace
		const getCardValue = (card) => {
			if (card.number === level) return 15; // Level card is treated as 15
			if (card.number === CardNumber.Ace) return 14; // Ace is treated as 14
			return card.number; // Other cards are treated by their numeric value
		};

		// Determine value based on combo type
		switch (this.type) {
			case ComboType.SingleCard:
			case ComboType.Pair:
			case ComboType.Triple:
			case ComboType.TripleWithPair:
				// For these types, return the value of any card (all cards have the same value)
				return getCardValue(this.cards[0]);

			case ComboType.Bomb:
				// For a Bomb, value is based on the card value, multiplied by 100 for each card beyond 4
				return (
					getCardValue(this.cards[0]) * Math.pow(100, this.cards.length - 4)
				);

			case ComboType.Straight:
			case ComboType.ConsecutivePairs: // Assuming ConsecutivePairs is a valid type in your setup
			case ComboType.TwoConsecutiveTriples: // Assuming TwoConsecutiveTriples is a valid type in your setup
				// For consecutive combos, disregard the level input and return the value of the lowest card
				return this.cards[0].number;

			case ComboType.StraightFlush:
				// For StraightFlush, value is 9900 + lowest card value (Ace considered as 1 here)
				return 9900 + this.cards[0].number;

			case ComboType.SkyBomb:
				// For SkyBomb, return Infinity as it beats everything
				return Infinity;

			default:
				// In case of an unrecognized type, return null or throw an error
				return null;
		}
	}

	get isBasicCombo() {
		return [
			ComboType.SingleCard,
			ComboType.Pair,
			ComboType.Triple,
			ComboType.TripleWithPair,
			ComboType.Straight,
			ComboType.ConsecutivePairs,
			ComboType.TwoConsecutiveTriples,
		].includes(this.type);
	}

	get isHigherOrderCombo() {
		return [
			ComboType.Bomb,
			ComboType.StraightFlush,
			ComboType.SkyBomb,
		].includes(this.type);
	}

	isValidMoveAfter(prevCardCombo, level) {
		// If both are basic combos, they must be of the same type and this combo's value must be greater
		if (this.isBasicCombo && prevCardCombo.isBasicCombo) {
			return (
				this.type === prevCardCombo.type &&
				this.getValue(level) > prevCardCombo.getValue(level)
			);
		}

		// If the previous combo is basic and this is higher order, it's always a valid move
		if (prevCardCombo.isBasicCombo && this.isHigherOrderCombo) {
			return true;
		}

		// If both are higher order combos, this combo's value must be greater, regardless of the type
		if (this.isHigherOrderCombo && prevCardCombo.isHigherOrderCombo) {
			return this.getValue(level) > prevCardCombo.getValue(level);
		}

		// All other possibilities are invalid
		return false;
	}
}

export function determineCardCombo(cards) {
	// Sort cards by their number for easier processing
	cards.sort((a, b) => a.number - b.number);

	// Utility functions
	const isSameSuit = (cards) =>
		cards.every((card, _, arr) => card.suit === arr[0].suit);
	const getCounts = (cards) =>
		cards.reduce((acc, card) => {
			acc[card.number] = (acc[card.number] || 0) + 1;
			return acc;
		}, {});

	const counts = getCounts(cards);
	const uniqueValues = Object.keys(counts)
		.map(Number)
		.sort((a, b) => a - b);
	const uniqueCounts = Object.values(counts);

	// Special case for SkyBomb
	if (
		cards.length === 4 &&
		uniqueValues.length === 2 &&
		uniqueValues.every((val) =>
			[CardNumber.SmallJoker, CardNumber.BigJoker].includes(val),
		)
	) {
		return new CardCombo(ComboType.SkyBomb, cards);
	}

	// General case checks
	switch (cards.length) {
		case 1:
			return new CardCombo(ComboType.SingleCard, cards);

		case 2:
			if (uniqueCounts.length === 1) {
				return new CardCombo(ComboType.Pair, cards);
			}
			break;

		case 3:
			if (uniqueCounts.length === 1) {
				return new CardCombo(ComboType.Triple, cards);
			}
			break;

		case 4:
			if (uniqueCounts.length === 1) {
				return new CardCombo(ComboType.Bomb, cards);
			}
			break;

		case 5:
			if (uniqueCounts.length === 1) {
				return new CardCombo(ComboType.Bomb, cards);
			}

			// Straight (including Aces high and low)
			if (uniqueCounts.length === 5) {
				let aceAdjustedNumbers = uniqueValues
					.map((val) => (val === CardNumber.Ace ? 14 : val))
					.sort((a, b) => a - b);
				if (isSequence(aceAdjustedNumbers)) {
					if (isSameSuit(cards)) {
						return new CardCombo(ComboType.StraightFlush, moveAcesToEnd(cards));
					} else {
						return new CardCombo(ComboType.Straight, moveAcesToEnd(cards));
					}
				} else if (isSequence(uniqueValues)) {
					if (isSameSuit(cards)) {
						return new CardCombo(ComboType.StraightFlush, cards);
					} else {
						return new CardCombo(ComboType.Straight, cards);
					}
				}
			}

			// TripleWithPair
			if (uniqueCounts.includes(3) && uniqueCounts.includes(2))
				return new CardCombo(
					ComboType.TripleWithPair,
					sortTripleWithPair(cards, counts),
				);
			break;

		case 6:
			if (uniqueCounts.length === 1) {
				return new CardCombo(ComboType.Bomb, cards);
			}
			// eslint-disable-next-line no-case-declarations
			const consecutivePairs = getConsecutivePairs(cards, uniqueValues, counts);
			if (consecutivePairs) {
				return new CardCombo(ComboType.ConsecutivePairs, consecutivePairs);
			}
			// eslint-disable-next-line no-case-declarations
			const twoConsecutiveTriples = getTwoConsecutiveTriples(
				cards,
				uniqueValues,
				counts,
			);
			if (twoConsecutiveTriples) {
				return new CardCombo(
					ComboType.TwoConsecutiveTriples,
					twoConsecutiveTriples,
				);
			}
			break;

		case 7:
		case 8:
			if (uniqueCounts.length === 1)
				return new CardCombo(ComboType.Bomb, cards);
			break;
	}

	return null; // No valid combination found
}

// Helper function to check for a sequence of numbers
function isSequence(arr) {
	return arr.every((val, i, array) => i === 0 || val - array[i - 1] === 1);
}

// Helper function for ConsecutivePairs
function getConsecutivePairs(cards, uniqueValues, counts) {
	// Check if all counts are exactly 2 (pairs) and then check for consecutive numbers
	if (
		uniqueValues.length * 2 !==
			Object.values(counts).reduce((acc, val) => acc + val, 0) ||
		!Object.values(counts).every((count) => count === 2)
	) {
		return false;
	}

	// Adjust Ace value if it should act as 14
	let adjustedValues = uniqueValues.map((val) =>
		val === CardNumber.Ace ? 14 : val,
	);
	adjustedValues.sort((a, b) => a - b);

	if (isSequence(adjustedValues)) {
		return moveAcesToEnd(cards);
	} else if (isSequence(uniqueValues)) {
		return cards;
	}
}

// Assuming TwoConsecutiveTriples requires 6 cards with two sets of triples in consecutive order
function getTwoConsecutiveTriples(cards, uniqueValues, counts) {
	// Check if all counts are exactly 3 (triples) and then check for consecutive numbers
	if (
		Object.keys(counts).length !== 2 ||
		!Object.values(counts).every((count) => count === 3)
	) {
		return false;
	}

	// Adjust Ace value if it should act as 14
	let adjustedValues = uniqueValues.map((val) =>
		val === CardNumber.Ace ? 14 : val,
	);
	adjustedValues.sort((a, b) => a - b);

	if (isSequence(adjustedValues)) {
		return moveAcesToEnd(cards);
	} else if (isSequence(uniqueValues)) {
		return cards;
	}
}

export function sortTripleWithPair(cards, counts) {
	// Separate the triple cards and pair cards based on their counts
	let tripleCards = [];
	let pairCards = [];
	for (let card of cards) {
		if (counts[card.number] === 3) {
			tripleCards.push(card);
		} else if (counts[card.number] === 2) {
			pairCards.push(card);
		}
	}

	// Ensure there are no duplicate cards in tripleCards and pairCards
	tripleCards = [...new Set(tripleCards)];
	pairCards = [...new Set(pairCards)];

	// Concatenate tripleCards in front of pairCards and update the cardCombo's cards
	return tripleCards.concat(pairCards);
}

function moveAcesToEnd(cards) {
	const aces = cards.filter((card) => card.number === CardNumber.Ace);
	const nonAces = cards.filter((card) => card.number !== CardNumber.Ace);
	const sortedCards = nonAces.concat(aces);
	return sortedCards;
}
