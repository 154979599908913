import { getDisplayValue, getImagePath } from './card';

import React from 'react';

function CardDisplay({ card }) {
	const displayValue = getDisplayValue(card);

	return (
		<div className="display-card">
			<img src={getImagePath(card)} alt={card.suit} />
			{displayValue && <div>{displayValue}</div>}
		</div>
	);
}

export function Table({ cardCombo }) {
	return (
		<div
			className="table flex-grow"
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'center',
				padding: '10px',
			}}
		>
			{cardCombo &&
				cardCombo.cards.map((card, index) => (
					<CardDisplay key={index} card={card} />
				))}
		</div>
	);
}
