import { CardNumber, CardSuit } from '../js/Card';

import React from 'react';

export const getImagePath = (card) => {
	if (card.suit === CardSuit.Joker) {
		return `assets/joker_${
			card.number === CardNumber.SmallJoker ? 'small' : 'big'
		}.svg`;
	} else {
		return `assets/${card.suit}.svg`;
	}
};

export const getDisplayValue = (card) => {
	if (card.suit === CardSuit.Joker) {
		return ''; // No display value for jokers
	}
	switch (card.number) {
		case CardNumber.Ace:
			return 'A';
		case CardNumber.J:
			return 'J';
		case CardNumber.Q:
			return 'Q';
		case CardNumber.K:
			return 'K';
		default:
			return card.number;
	}
};

export function Card({ card, isSelected, onClick }) {
	return card ? (
		<button
			className={'card' + (isSelected ? ' selected' : '')}
			style={{
				justifyContent:
					card.suit === CardSuit.Joker ? 'center' : 'space-between',
			}}
			onClick={onClick}
		>
			<img src={getImagePath(card)} alt={card.suit} />
			{card.suit != CardSuit.Joker && <span>{getDisplayValue(card)}</span>}
		</button>
	) : (
		<button className="card" disabled style={{ justifyContent: 'center' }}>
			<img src={'assets/cross.svg'} />
		</button>
	);
}
