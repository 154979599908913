import { Card, CardNumber, CardSuit } from '../js/Card'; // Assume these are defined in CardDefinitions.js

import { CardCombo } from '../js/CardCombo';
import { Deck } from './deck';
import { Mesh } from 'meshroom';
import React from 'react';
import { Table } from './table';
import randomatic from 'randomatic';

export const PLAYER_ID_PREFIX = 'guandanplayer-';

// Function to create a single deck of cards
function createDeck() {
	let deck = [];

	// Add standard cards
	Object.values(CardSuit).forEach((suit) => {
		if (suit !== CardSuit.Joker) {
			Object.values(CardNumber).forEach((number) => {
				if (number <= 13) {
					// Exclude Joker numbers
					deck.push(new Card(suit, number));
				}
			});
		}
	});

	// Add Jokers
	deck.push(new Card(CardSuit.Joker, CardNumber.SmallJoker));
	deck.push(new Card(CardSuit.Joker, CardNumber.BigJoker));

	return deck;
}

// Function to merge two decks and shuffle
function shuffleDecks(decks) {
	let combinedDeck = decks.flat();

	// Fisher-Yates (Durstenfeld) shuffle algorithm
	for (let i = combinedDeck.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[combinedDeck[i], combinedDeck[j]] = [combinedDeck[j], combinedDeck[i]];
	}

	return combinedDeck;
}

// Function to divide the shuffled deck into 4 piles
function divideIntoPiles(deck) {
	const piles = [[], [], [], []]; // Initialize 4 empty piles
	const pileSize = deck.length / 4; // Calculate the size of each pile

	for (let i = 0; i < 4; i++) {
		piles[i] = deck.slice(i * pileSize, (i + 1) * pileSize);
	}

	return piles;
}

// Main logic to get 4 piles of cards
function getCardPiles() {
	// Create two full decks
	const decks = [createDeck(), createDeck()];

	// Shuffle the two decks together
	const shuffledDeck = shuffleDecks(decks);

	// Divide the shuffled deck into 4 piles
	const cardPiles = divideIntoPiles(shuffledDeck);

	return cardPiles;
}

const mesh = new Mesh({
	id: PLAYER_ID_PREFIX + randomatic('A', 5, { exclude: '0oOiIlL1' }),
});
// Get the 27 randomly drawn cards

export function Root() {
	const [cardsInHand, setCardsInHand] = React.useState([]);
	const [cardComboOnTable, setCardComboOnTable] = React.useState(null);
	const [gameState, setGameState] = React.useState('waiting for connection');
	const [isMyTurn, setIsMyTurn] = React.useState(false);
	const codeBoxRef = React.useRef(null);

	React.useEffect(() => {
		mesh.on('nodeadded', (_node) => {
			setTimeout(() => {
				setGameState('in game');
				if (mesh.isHost && mesh.nodeIds.length === 3) {
					const cardPiles = getCardPiles();
					for (let i = 0; i < mesh.nodeIds.length; i++) {
						mesh.sharedCache.set('cards-' + mesh.nodeIds[i], cardPiles[i]);
					}
					mesh.sharedCache.set(
						'cards-' + mesh.id,
						cardPiles[mesh.nodeIds.length],
					);
					const randomSequence = [mesh.id, ...mesh.nodeIds].sort(
						() => Math.random() - 0.5,
					);
					mesh.sharedCache.set('sequence', randomSequence);
					mesh.sharedCache.set('turn', randomSequence[0]);
					mesh.sharedCache.set('cardComboOnTable', null);
				}
			}, 1000);
		});

		mesh.on('sharedupdate', () => {
			const cards = mesh.sharedCache.get('cards-' + mesh.id);
			setCardsInHand(cards);
			const turn = mesh.sharedCache.get('turn');
			setIsMyTurn(turn === mesh.id);
			if (turn === mesh.id && mesh.sharedCache.get('last-played') === mesh.id) {
				mesh.sharedCache.set('cardComboOnTable', null);
			}

			const cardCombo = mesh.sharedCache.get('cardComboOnTable');
			if (cardCombo) {
				setCardComboOnTable(new CardCombo(cardCombo.type, cardCombo.cards));
			} else {
				setCardComboOnTable(null);
			}

			[...mesh.nodeIds, mesh.id].forEach((id) => {
				const cards = mesh.sharedCache.get('cards-' + id);
				if (cards && cards.length === 0) {
					if (id === mesh.id) {
						setGameState('You win!');
					} else {
						setGameState('You lose!');
					}
				}
			});
		});
	}, []);

	return (
		<div className="container">
			<div className="fixed-height">
				<div
					className="input-group"
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<input
						ref={codeBoxRef}
						type="text"
						maxLength="5"
						placeholder={mesh.id.substring(PLAYER_ID_PREFIX.length)}
						className="input-text"
						style={{
							width: '60vw',
							height: '4vh',
							fontSize: '2.5rem',
							fontFamily: 'monospace',
							letterSpacing: '0.5em',
							paddingLeft: '0.5em',
						}}
					/>
					<button
						type="button"
						className="submit-btn"
						onClick={() => {
							mesh.connect(
								PLAYER_ID_PREFIX + codeBoxRef.current.value.toUpperCase(),
							);
						}}
						style={{
							width: '20vw',
							height: '4vh',
						}}
					>
						Submit
					</button>
				</div>
			</div>
			<div className="fixed-height">{gameState}</div>
			<Table cardCombo={cardComboOnTable} />
			<div className="fixed-height" style={{ height: '5px' }}></div>
			<Deck
				cards={cardsInHand}
				cardComboOnTable={cardComboOnTable}
				isMyTurn={isMyTurn}
				mesh={mesh}
			/>
		</div>
	);
}
